
.main-wrapper {
    height: 100%;
    display: flex;
    padding: 0 20px;
}
.main-left{
  z-index: 98;
  //background: #F6F7FC;
  position: relative;
  top: -20px;
  height: calc(100% + 20px);
  border-right: 20px solid #F6F7FC;
  ::v-deep .el-scrollbar__wrap{
    overflow-x: hidden;
  }
  .left-box{
    width: 210px;
    display: flex;
    flex-direction: column;
    .left-item{
      width: 100%;
      cursor: pointer;
      line-height: 1;
      margin: 16px 0;
      display: flex;
      span{
        line-height: 1;
        color: #666;
        font-size: 14px;
      }
      .span-active{
        color: #1AB163;
      }
    }
  }
}
.main-tight{
  flex: 1;
  width: 1%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}
.a-link {
    color: inherit;
}
